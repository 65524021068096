/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const deviceRef = { panels: 0, communicators: 0, recorders: 1, cameras: 2 };

var graphType, device_type;

const init_charts = function () {
  device_type = ['default']; //defined to based on the filter type Chart view changes for Sites availablity and site AddedRecently graph

  $("#signalStrengthChartInfo").hide();
  $("#siteAvailabilityChartInfo").hide();
  $("#siteConnectivityChartInfo").hide();

  $(".page-title").html(I18n.t("system_dashboard"));

  // firmware version graph code start here
  initGraphChart('#firmwareVersionChart', 'show_firmware_version_view', '#firmwareVersionLegend', 'firmware_status');

  if ($('#firmwareVersionChart').length > 0) {
    graphsFilter['show_firmware_version_view'](['default'])
  }
  // firmware version graph code ends here


  if ($("#firmwareVersionRecorderChart").length > 0) {
    const firmwareVersionRecorderChart = $("#firmwareVersionRecorderChart");
    let firmwareVersionRecorderinfo = firmwareVersionRecorderChart.data("value");
    if (!Array.isArray(firmwareVersionRecorderinfo)) {
      firmwareVersionRecorderinfo = [];
    }
    let dataCheck = false;
    if (firmwareVersionRecorderinfo && firmwareVersionRecorderinfo.length > 0)
    {
      dataCheck = true;
    }
    if (dataCheck)
    {
      const firmwareVersionRecorderPlot = $.plot(
        "#firmwareVersionRecorderChart",
        firmwareVersionRecorderinfo,
        {
          tooltip: {
            show: true,
            content: "%s: %n (%p.2%)",
            defaultTheme: false,
          },
          series: {
            pie: {
              show: firmwareVersionRecorderinfo.length > 0,
              radius: 0.8,
              label: {
                show: false,
              },
              background: {
                opacity: 0.8,
                color: "#000",
              },
            },
          },
          legend: {
            show: firmwareVersionRecorderinfo.length > 0,
            container: "#firmwareVersionRecorderLegend",
          },
          grid: {
            hoverable: firmwareVersionRecorderinfo.length > 0,
            clickable: firmwareVersionRecorderinfo.length > 0,
            borderWidth: 0
          },
        }
      );

      if (firmwareVersionRecorderPlot.getData().length === 0) {
        render_no_data_label(firmwareVersionRecorderPlot);
      }

      firmwareVersionRecorderChart
        .off("plotclick")
        .on("plotclick", function (event, pos, item) {
          if (item) {
            return (window.location.href =
              "/customers/find?customer_query[site_type]=1&customer_query[swver]=" +
              item.series.label);
          }
        });
    }
    else
    {
      $("#firmwareVersionRecorderChart").closest("div.dashboard-graph").hide();
    }
  }

  // central station account graph code start here
  initGraphChart('#centralStationAccountChart', 'show_central_station_account_view', '#centralStationAccountLegend', 'agency_name', true);

  if ($('#centralStationAccountChart').length > 0) {

    let centralStationPieChart = $("#centralStationAccountChart");
    let centralStationChartValues = getChartValues($("#centralStationAccountChart"));

    if (centralStationChartValues.length > 1) {
      centralStationPieChart.closest("div.dashboard-graph").css("display", "block");
      graphsFilter['show_central_station_account_view'](['default'])
    } else {
      centralStationPieChart.closest("div.dashboard-graph").hide();
    }
  }
  // central station account graph code ends here


  // Sites availability pie chart
  if ($("#siteAvailabilityChart").length > 0) {
    graphsFilter['show_site_availability_view'](active_filter_view('siteAvailabilityChart', 'month'))
  }

  // site connectivity chart
  if ($("#siteConnectivityChart").length > 0) {
    graphsFilter['show_site_connectivity_view'](['default'])
  }

  //Sites added recently pie chart for system admin start
  if ($("#sitesAddedRecentlyChart").length > 0) {
    graphType = "PIE_CHART";
    graphsFilter['show_sites_added_recently_view'](active_filter_view('sitesAddedRecentlyChart', 'month'))
  }

  if ($("#sitesAddedRecentlyBarChart").length > 0) {
    graphType = "BAR_CHART";
    graphsFilter['show_sites_added_recently_view'](['default'])
  }

  if ($("#signalStrengthChart").length > 0) {
    graphsFilter['show_signal_strength_view'](['default'])
  }

  // user requests bar chart graph start
  if ($("#userRequestBarChart").length > 0) {
    graphsFilter['show_user_request_view'](active_filter_view('userRequestBarChart', 'month'))
  }

  // user access log pie chart graph start
  if ($("#userAccessLogChart").length > 0) {
    graphsFilter['show_user_access_log_view'](active_filter_view('userAccessLogChart', 'month'))
  }

  // comms trouble graph code start here
  initEventGraphChart('#commTroubleEventBarChart', 'show_comm_trouble_event_view', '#commTroubleEventLegend');

  if ($('#commTroubleEventBarChart').length > 0) {
    graphsFilter['show_comm_trouble_event_view'](active_filter_view('commTroubleEventBarChart', 'day'))
  }

  // tamper status graph code start here
  initEventGraphChart('#tamperStatusBarChart', 'show_tamper_status_view', '#tamperStatusLegend');

  if ($("#tamperStatusBarChart").length > 0) {
    graphsFilter['show_tamper_status_view'](active_filter_view('tamperStatusBarChart', 'day'))
  }

  // power status graph code start here
  initEventGraphChart('#powerStatusBarChart', 'show_power_status_view', '#powerStatusLegend');

  if ($("#powerStatusBarChart").length > 0) {
    graphsFilter['show_power_status_view'](active_filter_view('powerStatusBarChart', 'day'))
  }

  if ($("#eventRoutingLoadChart").length > 0) {
    graphsFilter['show_event_routing_chart_view'](active_filter_view('eventRoutingLoadChart', 'day'))
  }

  if ($("#dataUsageBarChart").length > 0) {
    graphsFilter['show_data_usage_view'](['default'])
  }

  if ($("#eventCategoriesBarChart").length > 0) {
    graphsFilter['show_event_category_view'](['default'])
  }

  // panel types graph code start here
  initGraphChart('#panelTypesChart', 'show_panel_types_view', '#panelTypesLegend', 'product_type', true);

  if ($('#panelTypesChart').length > 0) {
    graphsFilter['show_panel_types_view'](['default'])
  }
  // panel types graph code ends here

  // service grade graph code starts here
  initGraphChart('#serviceGradeChart', 'show_service_grade_view', '#serviceGradeLegend', 'service_grade', true);

  if ($('#serviceGradeChart').length > 0) {
    graphsFilter['show_service_grade_view'](['default'])
  }
  // service grade graph code ends here

  if ($("#activeUsersChart").length > 0) {
    let active_opinfo = $("#activeUsersChart").data("value");
    if (!Array.isArray(active_opinfo)) {
      active_opinfo = [];
    }
    const activeUserPlot = $.plot("#activeUsersChart", active_opinfo, {
      tooltip: {
        show: true,
        content: "%s: %n (%p.2%)",
        defaultTheme: false,
      },
      series: {
        pie: {
          show: active_opinfo.length > 0,
          radius: 0.8,
          label: {
            show: false,
          },
          background: {
            opacity: 0.8,
            color: "#000",
          },
        },
      },
      legend: {
        show: active_opinfo.length > 0,
        container: "#activeUsersLegend",
      },
      grid: {
        hoverable: active_opinfo.length > 0,
        clickable: active_opinfo.length > 0,
        borderWidth: 0
      },
    });

    if (activeUserPlot.getData().length === 0) {
      render_no_data_label(activeUserPlot);
    }

    $("#activeUsersChart")
      .off("plotclick")
      .on("plotclick", function (event, pos, obj) {
        if (obj) {
          return (window.location.href =
            "/users/find?user_query[active_user]=" + obj.series.query_label );
        }
      });
  }

  // Active companies bar chart for system admin users only - Start
  if ($("#activeCompaniesBarChart").length > 0) {
    const chart = $("#activeCompaniesBarChart");
    let demo_active_companies = []
    let non_demo_active_companies = []

    let ac_bargraphs = chart.data("value");
    let dataCheck = false;

    if (ac_bargraphs && ac_bargraphs.length > 0) {
      demo_active_companies = ac_bargraphs[0].demo;
      non_demo_active_companies = ac_bargraphs[0].non_demo;

      if (demo_active_companies && demo_active_companies.length !== 0) {
        $.each(demo_active_companies, function (i, el) {
          if (el[1] > 0) {
            dataCheck = true;
            return false;
          }
        });
      }
      if (!dataCheck){
        if (non_demo_active_companies && non_demo_active_companies.length !== 0) {
          $.each(non_demo_active_companies, function (i, el) {
            if (el[1] > 0) {
              dataCheck = true;
              return false;
            }
          });
        }
      }
    }

    if (!Array.isArray(ac_bargraphs)) {
      ac_bargraphs = [{ ticks: [] }];
    }

    if (dataCheck){
      const ticks  = ac_bargraphs[0].ticks;
      const activeCompanyPlot = $.plot($("#activeCompaniesBarChart"),
        [
          { data: non_demo_active_companies,
            label: "Non Demo",
            bars: {
              barWidth: 0.3,
              lineWidth: 1,
              align: "right",
            }
          },
          { data: demo_active_companies,
            label: "Demo",
            bars: {
              barWidth: 0.3,
              lineWidth: 1,
              align: "left",
            },
          }
        ], {
        tooltip: {
          show: true,
          content: function(label, xval, yval, flotItem)
          {
              label = ticks[xval][1] + " ("+ label +")";
              return label+': '+ yval;
          },
          defaultTheme: false
        },
        series: {
          bars: {
            show: ticks.length > 0
          },
        },
        xaxis: {
          show: true,
          ticks: ticks,
          tickLength: 0,
          mode: 'categories'
        },
        yaxis: {
          show: true,
          tickDecimals: 0
        },
        grid: {
          hoverable: true,
          autoHighlight: true,
          mouseActiveRadius: 1,
          clickable: true,
          borderWidth: 0,
          backgroundColor: { colors: ["#ffffff", "#EDF5FF"] }
        },
        colors: ["#4DA74D","#CB4B4B"],
        legend: {
            show: true,
            container: "#activeCompaniesLegend"
          },
      });

      chart.off("plotclick").on("plotclick", function (event, pos, item) {
        if (item) {
          var label = ticks[item.dataIndex][2];
          var label_category = item.series.label;
          var is_demo = (label_category.toLowerCase() == "demo") ? true : false;
          return (window.location.href =
            "/companies/find?company_query[active_companies]=" + label + "&company_query[is_demo]="+ is_demo);
        }
      });
    }
    else {
      $("#activeCompaniesBarChart").closest("div.dashboard-graph").hide();
    }
  }
  // end

  if ($("#activeCompaniesChart").length > 0) {
    let active_opinfo = $("#activeCompaniesChart").data("value");
    if (!Array.isArray(active_opinfo)) {
      active_opinfo = [];
    }
    const activeUserPlot = $.plot("#activeCompaniesChart", active_opinfo, {
      tooltip: {
        show: true,
        content: "%s: %n (%p.2%)",
        defaultTheme: false,
      },
      series: {
        pie: {
          show: active_opinfo.length > 0,
          radius: 0.8,
          label: {
            show: false,
          },
          background: {
            opacity: 0.8,
            color: "#000",
          },
        },
      },
      legend: {
        show: active_opinfo.length > 0,
        container: "#activeCompaniesLegend",
      },
      grid: {
        hoverable: active_opinfo.length > 0,
        clickable: active_opinfo.length > 0,
        borderWidth: 0
      },
    });

    if (activeUserPlot.getData().length === 0) {
      render_no_data_label(activeUserPlot);
    }

    $("#activeCompaniesChart")
      .off("plotclick")
      .on("plotclick", function (event, pos, obj) {
        if (obj) {
          return (window.location.href =
            "/companies/find?company_query[active_companies]=" + obj.series.company_type_title );
        }
      });
  }

  // system performance graph code starts here
  initGraphChart('#systemPerformanceChart', 'show_system_performance_view', '#systemPerformanceLegend', 'systemstate');

  if ($('#systemPerformanceChart').length > 0) {
    graphsFilter['show_system_performance_view'](['default'])
  }
  // system performance graph code ends here

  if ($("#activeInterfaceChart").length > 0) {
    graphsFilter['show_active_interface_view'](['default'])
  }
};

var graphsFilter = {};
var filterTypes = ['day', 'week', 'month']

graphsFilter['show_user_access_log_view'] = function(chart_view) {
  let ual_opinfo = $("#userAccessLogChart").data("value");
  if (!Array.isArray(ual_opinfo) || ual_opinfo.length == 0) {
    ual_opinfo = [];
  } else {
    ual_opinfo = ual_opinfo[0][chart_view]
  }
  ual_opinfo = data_undefined_check(ual_opinfo);

  const userAccessLogPlot = $.plot("#userAccessLogChart", ual_opinfo, {
    tooltip: {
      show: true,
      content: "%s: %n (%p.2%)",
      defaultTheme: false,
    },
    series: {
      pie: {
        show: ual_opinfo.length > 0,
        radius: 0.8,
        label: {
          show: false,
        },
        background: {
          opacity: 0.8,
          color: "#000",
        },
      },
    },
    legend: {
      show: ual_opinfo.length > 0,
      container: "#userAccessLogLegend",
    },
    grid: {
      hoverable: ual_opinfo.length > 0,
      clickable: ual_opinfo.length > 0,
      borderWidth: 0
    },
  });

  if (userAccessLogPlot.getData().length === 0) {
    render_no_data_label(userAccessLogPlot);
  }

  $("#userAccessLogChart")
    .off("plotclick")
    .on("plotclick", function (event, pos, obj) {
      if (obj) {
        return (window.location.href =
          "/user_access_logs/find?user_access_log_query[acstype]=" +
          ual_opinfo[obj.seriesIndex].log_number +
          "&user_access_log_query[request_from]=dashboard&user_access_log_query[log_view]="+chart_view);
      }
    });
};

graphsFilter['show_sites_added_recently_view'] = function(chart_view) {
  //loading Piechart
  if (graphType === "PIE_CHART"){
    let sites_added_info = $("#sitesAddedRecentlyChart").data("value");

    if (filterTypes.indexOf(chart_view) < 0) {
      device_type = chart_view;
      chart_view = $("#sitesAddedRecentlyChart").parents('.chart-container').find('.filterActive').data('view');
    }
    if (!Array.isArray(sites_added_info) || sites_added_info.length == 0) {
      sites_added_info = [];
    } else {
      sites_added_info = sites_added_info[0][chart_view]
    }
    sites_added_info = data_undefined_check(sites_added_info);
    let final_data = getChartFilterData(device_type, sites_added_info);
    let plotData = getPlotData(final_data, '#sitesAddedRecentlyLegend');

    const sitesAddedPlot = $.plot("#sitesAddedRecentlyChart", final_data, plotData);

    if (sitesAddedPlot.getData().length === 0) {
      render_no_data_label(sitesAddedPlot);
    }

    let deviceTypesParam = generateDeviceTypesParam(device_type, '#sitesAddedRecentlyChart');

    $("#sitesAddedRecentlyChart")
      .off("plotclick")
      .on("plotclick", function (event, pos, item) {
        if (item) {
          if (item.series.label !== "Added Recently") {
            const sitedata = final_data[item.seriesIndex];
            return (window.location.href =
              "/customers/find?customer_query[sales_region]=" +
              sitedata.sales_region +
              "&customer_query[filter_type]=" +
              chart_view +
              "&customer_query[first_registration]=Added Recently" + deviceTypesParam);
          } else {
            return (window.location.href =
              "/customers/find?&customer_query[first_registration]=Added Recently");
          }
        }
      });
  }else{
    const chart = $("#sitesAddedRecentlyBarChart");
    let site_bargraph = chart.data("value");
    let dataCheck = false;


    if (!Array.isArray(site_bargraph)) {
      site_bargraph = [];
    }

    if (site_bargraph && site_bargraph.length > 0) {
      dataCheck = site_bargraph[0]["dataCheck"];
    }

    if (dataCheck) {
      let final_data = getBarChartAggregate(site_bargraph, chart_view, true);

      if (typeof final_data === "undefined") {
        final_data = site_bargraph;
      }

      const { ticks } = final_data[0];

      const sitesAddedBarPlot = $.plot($("#sitesAddedRecentlyBarChart"), final_data, {
        series: {
          bars: {
            show: ticks.length > 0,
            horizontal: true,
          },
        },
        bars: {
          align: "center",
          barWidth: 0.5,
        },
        xaxis: {
          show: false,
        },
        yaxis: {
          position: "left",
          show: true,
          ticks,
          tickColor: "#fff",
        },
        grid: {
          hoverable: true,
          autoHighlight: true,
          mouseActiveRadius: 1,
          clickable: true,
          borderWidth: 0,
          markings:  [{ xaxis: { from: 0, to: 0 }, color: "#eee" }],
        },
      });

      if (ticks.length === 0) {
        render_no_data_label(sitesAddedBarPlot);
      }

      let deviceTypesParam = generateDeviceTypesParam(chart_view, '#sitesAddedRecentlyBarChart');

      chart.off("plotclick").on("plotclick", function (event, pos, item) {
        if (item) {
          return (window.location.href =
            "/customers/find?&customer_query[sites_added_in_week]=" +
            item.series.ticks[item.dataIndex][2] + deviceTypesParam);
        }
      });

      $.each(sitesAddedBarPlot.getData()[0].data, function (i, el) {
        if (el[0] > 0) {
          const o = sitesAddedBarPlot.pointOffset({
            x: el[0],
            y: el[1],
          });

          $('<div class="data-point-label">' + el[0] + "</div>")
            .css({
              position: "absolute",
              right: -20,
              top: o.top - 5,
              display: "none",
            })
            .appendTo(sitesAddedBarPlot.getPlaceholder())
            .fadeIn("slow");
        }
      });
    } else {
      $("#sitesAddedRecentlyBarChart").closest("div.dashboard-graph").hide();
    }
  }
};

graphsFilter['show_site_availability_view'] = function(chart_view) {
  const siteAvailabilityChart = '#siteAvailabilityChart';

  if (filterTypes.indexOf(chart_view) < 0) {
    device_type = chart_view;
    chart_view = $(siteAvailabilityChart).parents('.chart-container').find('.filterActive').data('view');
  }

  let site_avail_data = $(siteAvailabilityChart).data("value");
  if (!Array.isArray(site_avail_data) || site_avail_data.length == 0) {
    sa_opinfo = [];
  } else {
    sa_opinfo = site_avail_data[0][chart_view];
  }

  let dataCheck = pieGraphDataCheck(site_avail_data);

  if (dataCheck)
  {
    let final_data = getChartFilterData(device_type, sa_opinfo);
    let plotData = getPlotData(final_data, '#siteAvailabilityLegend');
    const siteAvailabilityPlot = $.plot(siteAvailabilityChart, final_data, plotData);

    if (siteAvailabilityPlot.getData().length === 0) {
      render_no_data_label(siteAvailabilityPlot);
    }

    let deviceTypesParam = generateDeviceTypesParam(device_type, '#siteAvailabilityChart');

    $(siteAvailabilityChart)
      .off("plotclick")
      .on("plotclick", function (event, pos, item) {
        if (item) {
          const sitedata = final_data[item.seriesIndex];
          return (window.location.href =
            "/customers/find?customer_query[filter_type]="+ chart_view +"&customer_query[availability]="+ sitedata.index + deviceTypesParam);
        }
      });

    $("#siteAvailabilityChartInfo").show();
    $("#siteAvailabilityChartInfo span").text(I18n.t("availability_graph_info"));
  }
  else  {
    $(siteAvailabilityChart).closest("div.dashboard-graph").hide();
  }
};

graphsFilter['show_active_interface_view'] = function(chart_view) {

  let interface_info = $("#activeInterfaceChart").data("value");
    let dataCheck = false;
    if (!Array.isArray(interface_info)) {
      interface_info = [];
    }
    if (interface_info && interface_info.length > 0) {
      dataCheck = true;
    }

    if (dataCheck) {
      let final_data = getChartFilterData(chart_view, interface_info);
      let plotData = getPlotData(final_data, '#activeInterfaceLegend');

      const activeInterfacePlot = $.plot("#activeInterfaceChart", final_data, plotData);

      if (activeInterfacePlot.getData().length === 0) {
        render_no_data_label(activeInterfacePlot);
      }

      let deviceTypesParam = generateDeviceTypesParam(chart_view, "#activeInterfaceChart");

      $("#activeInterfaceChart")
        .off("plotclick")
        .on("plotclick", function (event, pos, obj) {
          if (obj) {
            return (window.location.href =
              "/customers/find?customer_query[interfacetype]=" + obj.series.interface_type +  deviceTypesParam );
          }
        });
    }
    else
    {
      $("#activeInterfaceChart").closest("div.dashboard-graph").hide();
    }
};

graphsFilter['show_signal_strength_view'] = function(chart_view) {
  let signal_strength_opinfo = $("#signalStrengthChart").data("value");

  let dataCheck = false;
  if (!Array.isArray(signal_strength_opinfo)) {
    signal_strength_opinfo = [];
  }

  if (signal_strength_opinfo && signal_strength_opinfo.length > 0) {
    if(signal_strength_opinfo[0].hasOwnProperty("default") && signal_strength_opinfo[0]["default"].length > 0) {
      dataCheck = true;
      $("#signalStrengthChartInfo").show();
      $("#signalStrengthChartInfo span").text(I18n.t("signal_strength_info"));
    }
  }

  if (dataCheck) {
    let final_data = getChartFilterData(chart_view, signal_strength_opinfo);
    let plotData = getPlotData(final_data, '#signalStrengthLegend');

    const signalStrengthPlot = $.plot("#signalStrengthChart", final_data, plotData);

    if (signalStrengthPlot.getData().length === 0) {
      render_no_data_label(signalStrengthPlot);
    }

    let deviceTypesParam = generateDeviceTypesParam(chart_view, "#signalStrengthChart");

    $("#signalStrengthChart")
      .off("plotclick")
      .on("plotclick", function (event, pos, obj) {
        if (obj) {
          return (window.location.href =
            "/customers/find?customer_query[signal_status]=" +
            obj.series.label + deviceTypesParam);
        }
      });
  } else {
    $("#signalStrengthChart").closest("div.dashboard-graph").hide();
  }
}

graphsFilter['show_site_connectivity_view'] = function(chart_view) {

  let connectivity_opinfo = $("#siteConnectivityChart").data("value");
  let dataCheck = false;

  if (!Array.isArray(connectivity_opinfo)) {
    connectivity_opinfo = [];
  }
  if (connectivity_opinfo && connectivity_opinfo.length > 0) {
    if(connectivity_opinfo[0].hasOwnProperty("default") && connectivity_opinfo[0]["default"].length > 0) {
      dataCheck = true;
      $("#siteConnectivityChartInfo").show();
      $("#siteConnectivityChartInfo span").text(I18n.t("connectivity_graph_info"));
    }
  }

  if (dataCheck)
  {
    let final_data = getChartFilterData(chart_view, connectivity_opinfo);
    let plotData = getPlotData(connectivity_opinfo, '#siteConnectivityLegend');

    const siteConnectivityPlot = $.plot("#siteConnectivityChart", final_data, plotData);

    if (siteConnectivityPlot.getData().length === 0) {
      render_no_data_label(siteConnectivityPlot);
    }

    let deviceTypesParam = generateDeviceTypesParam(chart_view, "#siteConnectivityChart");

    $("#siteConnectivityChart")
      .off("plotclick")
      .on("plotclick", function (event, pos, obj) {
        if (obj) {
          return (window.location.href =
            "/customers/find?customer_query[adminstate]=" + obj.series.label  +  deviceTypesParam);
        }
      });
  }
  else
  {
    $("#siteConnectivityChart").closest("div.dashboard-graph").hide();
  }
}


graphsFilter['show_user_request_view'] = function(chart_view) {
  let us_chart = $("#userRequestBarChart");
  let ur_opinfo = $("#userRequestBarChart").data("value");
  let data = ur_opinfo[0][chart_view]['data'];
  let dataset = [{data: data, color: "#5482FF" }];
  let ticks = ur_opinfo[0][chart_view]['ticks'];
  let dataCheck = false;

  if (dataset && dataset.length > 0) {
    if (dataset[0] && dataset[0].data && dataset[0].data.length !== 0) {
      $.each(dataset[0].data, function (i, el) {
        if (el[0] >= 0) {
          dataCheck = true;
          return false;
        }
      });
    }
  }

  if (!Array.isArray(dataset) || dataset.length == 0 || dataset[0].data === undefined) {
    dataset = [{ ticks: [] }];
    ticks = []
  }

  let options = {
      tooltip: {
          show: true,
          content: function(label, xval, yval, flotItem)
          {
              return ticks[xval][1]+': '+ yval;
          },
          defaultTheme: false
      },
      series: {
          bars: {
              show: true
          }
      },
      bars: {
          align: (dataCheck && dataset[0].data.length < 5) ? "center" : "left",
          barWidth: 0.3,
          lineWidth: 1
      },
      xaxis: {
          ticks: ticks,
          mode: "categories",
          tickLength: 1
      },
      yaxis: {
          tickDecimals: 0,
          min: 0
      },
      legend: {
          show: false,
      },
      grid: {
          hoverable: true,
          borderWidth: 0,
          autoHighlight: true,
          mouseActiveRadius: 1,
          clickable: dataCheck,
          backgroundColor: { colors: ["#ffffff", "#EDF5FF"] }
      }
  };

  const userRequestPlot =  $.plot($("#userRequestBarChart"), dataset, options);

    if (ticks.length === 0 || !dataCheck) {
      render_no_data_label(userRequestPlot);
    }

    us_chart.off("plotclick").on("plotclick", function (event, pos, obj) {
      if (obj) {
        return (window.location.href =
          "/user_requests/find?user_request_query[request_type]=" +
          ur_opinfo[0][chart_view]["request_type"][ticks[obj.dataIndex][0]][1] +
          "&user_request_query[request_from]=dashboard&user_request_query[event_view]="+chart_view);
      }
    });

};

const initEventGraphChart = function (chartType, graphFilterView, legendContainer) {

  graphsFilter[graphFilterView] = function(chart_view) {
    const chart = $(chartType);

    if (filterTypes.indexOf(chart_view) < 0) {
      device_type = chart_view;
      chart_view = chart.parents('.chart-container').find('.filterActive').data('view');
    }

    let chartValue = chart.data("value");
    let bargraph = chartValue[0].hasOwnProperty('default') ? getBarChartFilterData(device_type,chartValue) : chartValue;
    let bargraphData = chartValue[0].hasOwnProperty('default') ? chartValue[0]['default'] : chartValue;
    let dataCheck = barGraphDataCheck(bargraphData);

    if (!Array.isArray(bargraph) || bargraph.length === 0) {
      bargraph = [{ ticks: [] }];
    }

    if (dataCheck) {
      let ticks  = bargraph[0].ticks;
      let bargraphEventData = ticks.length > 0 ? bargraph[0][chart_view].data_event : [];
      let bargraphRestoreData = ticks.length > 0 ? bargraph[0][chart_view].data_restore : [];
      let bargraphData = [
        {
          data: bargraphEventData,
          label: "Event",
          bars: {
            barWidth: 0.3,
            lineWidth: 1,
            align: "right",
          },
        },
        {
          data: bargraphRestoreData,
          label: "Restore",
          bars: {
            barWidth: 0.3,
            lineWidth: 1,
            align: "left",
          },
        },
      ]
      let plotData = getTroublePlotData(ticks, legendContainer);
      const troubleEventPlot = $.plot(chart, bargraphData, plotData);

      if (ticks.length === 0) {
        render_no_data_label(troubleEventPlot);
      }

      let deviceTypesParam = generateDeviceTypesParam(device_type, chartType);

      chart.off("plotclick").on("plotclick", function (event, pos, item) {
        if (item) {
          return (window.location.href =
            "/customers/find?&customer_query[event_code]="
            +ticks[item.dataIndex][2]+"&customer_query[qualifier_type]="+item.series.label+"&customer_query[event_view]="+chart_view+deviceTypesParam);
        }
      });
    } else {
      chart.closest("div.dashboard-graph").hide();
    }
  };
}


const getTroublePlotData = function(ticks, legendContainer) {
  return {
    series: {
      bars: {
        show: ticks.length > 0
      },
    },
    xaxis: {
      show: true,
      ticks: ticks,
      tickLength: 0,
      mode: 'categories'
    },
    yaxis: {
      show: true,
      tickDecimals: 0,
      min: 0
    },
    grid: {
      hoverable: true,
      autoHighlight: true,
      mouseActiveRadius: 1,
      clickable: true,
      borderWidth: 0,
      backgroundColor: { colors: ["#ffffff", "#EDF5FF"] }
    },
    colors: ["#CB4B4B","#4DA74D"],
    legend: {
        show: true,
        container: legendContainer
      },
  }
}


var getChartFilterData = function(selectedCategories, originalData) {
    let result = [];
    if (selectedCategories.length === 0 || !originalData || !Array.isArray(originalData)) {
        return result;  // Return empty array if no original data
    }

    // Loop through categories
    selectedCategories.forEach(category => {
      // Find the object with the current category
      const categoryObject = originalData.find(obj => obj.hasOwnProperty(category));
      if (categoryObject) {
        // Retrieve and log the value associated with the category
        const categoryValue = categoryObject[category];
        result = result.concat(categoryValue);
      }
    });

    const summedResult = result.reduce((acc, item) => {
        // Create a key based on label and sales_region
        let key;

        key = item.hasOwnProperty('sales_region') ? `${item.label}-${item.sales_region}` : `${item.label}`;

        // If the key doesn't exist in the accumulator, create it with initial data value
        !acc[key] ? acc[key] = { ...item } : acc[key].data += item.data;

        return acc;
    }, {});

    // Convert the result back to an array
    return Object.values(summedResult);
}

var getBarChartFilterData = function(selectedCategories, originalData) {
  let result = [];

  if (selectedCategories.length === 0 || !originalData || !Array.isArray(originalData)) {
      return result;  // Return empty array if no original data
  }

  // If no filter is applied, this will return default data.
  if (selectedCategories.length === 1 && selectedCategories[0] === "default") {
    return originalData[0].default;
  }

  // Loop through categories
  selectedCategories.forEach(category => {
    // Find the object with the current category
    const categoryObject = originalData.find(obj => obj.hasOwnProperty(category));
    if (categoryObject) {
      // Retrieve and log the value associated with the category
      const categoryValue = categoryObject[category];
      result = result.concat(categoryValue);
    }
  });

  let summedResult = {};
  result.forEach(item => {
    // If the summedResult is empty, create it with initial item
    if (Object.keys(summedResult).length === 0) {
      summedResult = JSON.parse(JSON.stringify(item));
    } else {
      filterTypes.forEach(interval => {
        for (const [key, value] of Object.entries(summedResult[interval])) {
          value.forEach((data_item, index) => {
            summedResult[interval][key][index][1] += item[interval][key][index][1];
          });
        }
      });
    }
  });

  return [summedResult];
}

graphsFilter['show_event_routing_chart_view'] = function(chart_view) {
  event_stats_chart = $("#eventRoutingLoadChart");
  view = $(".event-route-filter .filterActive").data("view");
  dataValue = event_stats_chart.data("value");
  tooltip_opt = {
                  show: true,
                  content: function(label, xval, yval, flotItem)
                  {
                      return formatTime(new Date(xval))+': '+ yval;
                  },
                  defaultTheme: false
                }
  if (dataValue && dataValue.length > 0) {
    opinfo = [dataValue[0][chart_view][0]];
    date_from = new Date(parseInt(dataValue[0][chart_view][1]));
    date_to = new Date(parseInt(dataValue[0][chart_view][2]));
    getOptionsFromView = function () {
      if (view === "day") {
        return {
        tooltip: tooltip_opt,
          xaxis: {
            mode: "time",
            timeBase: "milliseconds",
            minTickSize: [1, "hour"],
            min: date_from.getTime(),
            max: date_to.getTime(),
            twelveHourClock: true,
          },
          yaxis: {
            min: 0
          },
          grid: {
            hoverable: true,
            autoHighlight: true,
          },
        };
      } else if (view === "week") {
        return {
        tooltip: tooltip_opt,
          xaxis: {
            mode: "time",
            minTickSize: [1, "day"],
            min: date_from.getTime(),
            max: date_to.getTime(),
            dayNames: ["Sun", "Mon", "Tue", "Wed", "Thr", "Fri", "Sat"],
            tickFormatter: function (val, axis) {
              return new Date(val).toString().split(" ")[0];
            },
          },
          yaxis: {
            min: 0
          },
          grid: {
            hoverable: true,
            autoHighlight: true,
            mouseActiveRadius: 1,
          },
        };
      }
        else if (view === "month") {
        return {
        tooltip: tooltip_opt,
          xaxis: {
            mode: "time",
            minTickSize: [2, "day"]
          },
          yaxis: {
            min: 0
          },
          grid: {
            hoverable: true,
            autoHighlight: true,
            mouseActiveRadius: 1,
          },
        };
      }
    };
    $.plot("#eventRoutingLoadChart", opinfo, getOptionsFromView());
    formatTime = function (date) {
      var ampm, dayOfWeek, hours, minutes, strTime;
      dayOfWeek = function (dow) {
        var days;
        days = [I18n.t('reports_set.sunday'), I18n.t('reports_set.monday'), I18n.t('reports_set.tuesday'), I18n.t('reports_set.wednesday'),
                I18n.t('reports_set.thursday'), I18n.t('reports_set.friday'), I18n.t('reports_set.saturday')];
        return days[dow];
      };
      monthName = function (month) {
        var months;
        months = [I18n.t('reports_set.january'), I18n.t('reports_set.february'), I18n.t('reports_set.march'), I18n.t('reports_set.april'),
                  I18n.t('reports_set.may'), I18n.t('reports_set.june'), I18n.t('reports_set.july'), I18n.t('reports_set.august'),
                  I18n.t('reports_set.september'), I18n.t('reports_set.october'), I18n.t('reports_set.november'), I18n.t('reports_set.december')]
        return months[month];
      };
      hours = date.getUTCHours();
      minutes = date.getUTCMinutes();
      ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      strTime = hours + ":" + minutes + " " + ampm;
      if (view) {
        if (view != 'month' ){
          return (strTime = dayOfWeek(date.getUTCDay()) + " - " + strTime);
        }
        else {
          return (strTime = monthName(date.getUTCMonth()) + " " + date.getDate() + " - " + strTime);
        }
      }
    };
  } else {
    const eventRoutingLoadPlot = $.plot("#eventRoutingLoadChart", [], {});
    render_no_data_label(eventRoutingLoadPlot);
  }
};

graphsFilter['show_data_usage_view'] = function(chart_view){
  const dataUsageChart = $("#dataUsageBarChart");
  let dataUsageInfo = dataUsageChart.data("value");
  if (!Array.isArray(dataUsageInfo)) {
    dataUsageInfo = [];
  }
  let dataCheck = false;
  if (dataUsageInfo && dataUsageInfo.length > 0 )
  {
    dataCheck = dataUsageInfo[0]["dataCheck"]
  }

  if (dataCheck) {
    let final_data = getBarChartAggregate(dataUsageInfo, chart_view, false)

    if (typeof final_data === "undefined") {
      final_data = dataUsageInfo //[0]["default"]
    }
    var dataset = [{ data: final_data[0]["data"] }];
    var ticks   = final_data[0]["ticks"];

    const dataUsagePlot = $.plot($("#dataUsageBarChart"), dataset,{
      tooltip: {
        show: true,
        content: function(label, xval, yval, flotItem)
        {
            return ticks[xval][1]+': '+ yval;
        },
        defaultTheme: false
      },
      series: {
        bars: {
          show: true
        }
      },
      bars: {
        align: "center",
        barWidth: 0.5
      },
      xaxis: {
        show: true,
        ticks: ticks,
        tickLength: 0
      },
      yaxis: {
        show: true,
        tickDecimals: 0,
        min: 0
      },
      grid: {
        hoverable: true,
        autoHighlight: true,
        mouseActiveRadius: 1,
        clickable: true,
        borderWidth: 0,
        backgroundColor: { colors: ["#ffffff", "#EDF5FF"] }
      },
      colors: ["#CB4B4B","#4DA74D"]
    });
    let deviceTypesParam = generateDeviceTypesParam(chart_view, "#dataUsageBarChart");

    dataUsageChart.off("plotclick").on("plotclick", function (event, pos, item) {
      var label;
      if (item) {
        label = ticks[item.dataIndex][1];
        return (window.location.href =
            "/customers/find?customer_query[data_usage_status]="+ label.replace(' ','_') +deviceTypesParam);
      }
    });

  } else {
    $("#dataUsageBarChart").closest("div.dashboard-graph").hide();
  }
}

graphsFilter['show_event_category_view'] = function(chart_view){
    const eventCategoryChart = $("#eventCategoriesBarChart");
  let eventCategoryInfo = eventCategoryChart.data("value");

  if (!Array.isArray(eventCategoryInfo)) {
    eventCategoryInfo = [];
  }
  let dataCheck = false;
  if (eventCategoryInfo && eventCategoryInfo.length > 0 )
  {
    dataCheck = eventCategoryInfo[0]
  }
  if (dataCheck) {
    let final_data = getBarChartAggregate(eventCategoryInfo, chart_view, 1);

    if (typeof final_data === "undefined") {
        final_data = eventCategoryInfo;
    }

    var rawData = final_data[0]["data"];
    var ticks = final_data[0]["ticks"];

    var dataset = rawData.map(function(entry) {
        return [entry[0], entry[1][1]];
    });

    var formattedTicks = ticks.map(function(entry) {
        return [entry[0], entry[1]];
    });

    const dataUsagePlot = $.plot($("#eventCategoriesBarChart"), [{
        data: dataset,
        bars: { show: true }
    }], {
        tooltip: {
            show: true,
            content: function(label, xval, yval) {
                return ' ' + yval + ' ' + I18n.t("threshold_label");
            },
            defaultTheme: false
        },
         series: {
        bars: {
          show: true
        }
      },
      bars: {
        align: "center",
        barWidth: 0.5
      },
      xaxis: {
        show: true,
        ticks: ticks,
        tickLength: 0
      },
      yaxis: {
        tickDecimals: 0,
        min: 0
      },
      grid: {
        hoverable: true,
        autoHighlight: true,
        mouseActiveRadius: 1,
        clickable: true,
        borderWidth: 0,
        backgroundColor: { colors: ["#ffffff", "#EDF5FF"] }
      },
      colors: ["#CB4B4B","#4DA74D"]
    });

    eventCategoryChart.off("plotclick").on("plotclick", function (event, pos, item) {
      var label;
      if (item) {
        var index = item.dataIndex;
        return (window.location.href =
            "/ultralink_events/find?ultralink_event_query[categories]=" + rawData[index][1][0].map(String) +
                                "&ultralink_event_query[first_uatime]=" +  ticks[item.dataIndex][1])
      }
    });
  } else {
    $("#eventCategoriesBarChart").closest("div.dashboard-graph").hide();
  }
}



// Setting up device type parameters for the device type filter to be applied after redirection.
var generateDeviceTypesParam = function(filter_categories, chartType) {
  if (filter_categories.length === 0) return "";

  let divId = $(chartType).parents('.chart-container').parent().attr('id');
  let divIdNumber = divId.split('-')[1];
  let childrenLength = $('.filterForm-' + divIdNumber).children().length;
  let childrenChecked = $('.filterForm-' + divIdNumber).find('input[type="checkbox"]:checked').length;

  if ((filter_categories.length === 1) && filter_categories[0] === 'default') {
    let deviceRefCount = { panels: '0', all: '0,1,2'}

    if (childrenLength === 2) {
      return "&customer_query[is_communicator]=true&customer_query[device_types]=" + deviceRefCount['panels'] + "&customer_query[default_device_types]=true";
    } else {
      return "&customer_query[is_communicator]=true&customer_query[device_types]=" + deviceRefCount['all'] + "&customer_query[default_device_types]=true";
    }
  }

  let deviceTypes = [];
  let deviceTypesParam = "";

  if (childrenLength === childrenChecked) {
    deviceTypesParam += "&customer_query[default_device_types]=true";
  }

  filter_categories.forEach(el => {
    if (el !== 'default' && deviceRef.hasOwnProperty(el) && !deviceTypes.includes(deviceRef[el])) {
      deviceTypes.push(deviceRef[el]);
    }
  });

  if (filter_categories.includes('communicators')) {
    deviceTypesParam += "&customer_query[is_communicator]=true";
    if (!filter_categories.includes('panels')) {
      deviceTypes = deviceTypes.filter(item => item !== deviceRef['panels']);
    }
  }

  if (deviceTypes.length > 0) {
    deviceTypesParam += "&customer_query[device_types]=" + deviceTypes.join(",");
  }

  return deviceTypesParam;
}

// Aggregating certain device filter values here.
var getBarChartAggregate = function(data, categories, is_vertical_bar) {
  //Verifying the type of graph vertical or horizantal Graph and assigning the dataindex
  let dataIndex =  is_vertical_bar ? 0 : 1;
  // If no filter is applied, this will return default data.
  if (categories.length === 1 && categories[0] === "default") {
    return data[0].default;
  }

  // Initialize the result structure with the default ticks but zeroed data values.
  let result = JSON.parse(JSON.stringify(data[0].default));
  result.forEach(item => {
    item.data.forEach(dataPoint => {
      dataPoint[dataIndex] = 0;  // Initialize all data points to zero.
    });
  });

  // Iterate over each category.
  categories.forEach(category => {
    if (data[0][category]) {
      data[0][category].forEach((item, index) => {
        item.data.forEach((dataPoint, dpIndex) => {
          if (dataPoint) {
            result[index].data[dpIndex][dataIndex] += dataPoint[dataIndex];
          }
        });
      });
    }
  });

  return result;
};


var render_no_data_label = function (graphPlot) {
  const canvas = graphPlot.getCanvas();
  const ctx = canvas.getContext("2d");
  const x = canvas.width / 2;
  const y = canvas.height / 2;
  ctx.textAlign = "center";
  ctx.font = "12px sans-serif";
  return ctx.fillText(I18n.t("no_data_available"), x, y);
};

// Assign an empty array if any of the graph's data is undefined.
var data_undefined_check = function(graphData){
  return (typeof graphData !== 'undefined') ? graphData : [];
};

var barGraphDataCheck = function(graphData) {
  let dataCheck = false;
  if (graphData && graphData.length > 0) {
    $.each(filterTypes, function (index, element) {
      if (typeof graphData[0][element] !== 'undefined') {
        bargraph_event   = graphData[0][element].data_event;
        bargraph_restore = graphData[0][element].data_restore;
        if (bargraph_event && bargraph_event.length !== 0) {
          $.each(bargraph_event, function (i, el) {
            if (el[1] > 0) {
              dataCheck = true;
              return false;
            }
          });
        }
        if (!dataCheck){
          if (bargraph_restore && bargraph_restore.length !== 0) {
            $.each(bargraph_restore, function (i, el) {
              if (el[1] > 0) {
                dataCheck = true;
                return false;
              }
            });
          }
        }
      }
    });
  }
  return dataCheck;
};

var pieGraphDataCheck = function(graphData) {
  let dataCheck = false;
  if (graphData && graphData.length > 0) {
    $.each(filterTypes, function (index, element) {
      let filtered_data = graphData[0][element][0]['default'];
      if (filtered_data && filtered_data.length > 0) {
        dataCheck = true;
        return false;
      }
    });
  }
  return dataCheck;
};

var active_filter_view = function(chart_id, default_view){
  var active_view = $("#"+chart_id).parents('.chart-container').find('.filterActive').data('view');
  return (typeof active_view === 'undefined') ? default_view : active_view;
};

const initGraphChart = function (chartType, graphFilterView, legendContainer, customerQueryParam, exactMatch=false) {

  graphsFilter[graphFilterView] = function(chart_view) {
    let ptInfo = $(chartType).data("value");

    if (!Array.isArray(ptInfo)) { ptInfo = []; }

    let finalData = getChartFilterData(chart_view, ptInfo);

    if (exactMatch) {
      finalData = sortFinalResults(finalData)
    }

    if (finalData.length === 0 ) { ptInfo = []; }

    let plotData = getPlotData(ptInfo, legendContainer);

    const graphPlot = $.plot(chartType, finalData, plotData);

    if (graphPlot.getData().length === 0) {
      render_no_data_label(graphPlot);
    }

    let exactMatchParam = '';
    if (exactMatch) { exactMatchParam = "&customer_query[exact_match]=true"; }

    let deviceTypesParam = generateDeviceTypesParam(chart_view, chartType);

    $(chartType)
      .off("plotclick")
      .on("plotclick", function (event, pos, obj) {
        if (obj) {
          var obj_label;
          if (customerQueryParam == "agency_name") {
            obj_label = encodeURIComponent(obj.series.label)
          } else {
            obj_label = obj.series.label
          }
          return (window.location.href =
            "/customers/find?customer_query[" + customerQueryParam + "]=" + obj_label + exactMatchParam +  deviceTypesParam)
        }
      });

  }
}

// Apply sorting(data) and get the top 5 results
const sortFinalResults = function(data) {
  data.sort((a, b) =>
    b.data - a.data || a.label.localeCompare(b.label)
  );

  // Slice the top 5 results based on data count from the sorted array
  const topCountData = data.slice(0, 5);

  return topCountData;
}

const getPlotData = function(plotData, legendContainer) {
  return {
    tooltip: {
      show: true,
      content: "%s: %n (%p.2%)",
      defaultTheme: false,
    },
    series: {
      pie: {
        show: plotData.length > 0,
        radius: 0.8,
        label: {
          show: false,
        },
        background: {
          opacity: 0.8,
          color: "#000",
        },
      },
    },
    legend: {
      show: plotData.length > 0,
      container: legendContainer,
    },
    grid: {
      hoverable: plotData.length > 0,
      clickable: plotData.length > 0,
      borderWidth: 0
    },
  }
}

const getChartValues = function(chartId) {

  let chartDataValue = chartId.data("value");
  let chartValues = Object.values(chartDataValue[0]);

  if (!Array.isArray(chartValues.flat())) {
    chartValues = [];
  }
  return chartValues;
}

// Function to update the filter icon based on device type filter form checkboxes selection
const updateFilterIcon = function(divClass, clickedId) {
  var allUnchecked  = $("." + divClass + " input[type='checkbox']:checked").length === 0;
  var $filterAnchor = $('a.flt-' + clickedId);

  // Update the classes based on the checkbox state
  $filterAnchor.removeClass('filterActive filterIcon')
               .addClass(allUnchecked ? 'filterIcon' : 'filterActive');
}


jQuery(() =>
  run_ontag("customers_dashboard", function () {
    init_charts();
    $("#menu_toggle").on("click", () =>
      window.setTimeout(() => init_charts(), 0)
    );
    // Filtering a graph based on click
    $(".flt-onclick").on("click", function (e) {
      $(this).siblings('.flt-onclick').removeClass('filterActive').addClass('filterIcon');
      $(this).removeClass('filterIcon').addClass('filterActive');
      var dataView = $(this).attr("data-view");
      var dataName = $(this).attr("data-name");
      var graph_method = "show_"+dataName;
      graphsFilter[graph_method](dataView);
      return false;
    });

    $(".flt-category").on("click", function (e) {
      return false
    });

    $('#filterForm input[type="checkbox"]').click(function() {
      // Get all checked checkbox values
      var checkedValues = $(this).closest('div#filterForm').find('input[type="checkbox"]:checked').map(function() {
          return $(this).val().toLowerCase();
      }).get();

      let divClass = $(this).closest('div#filterForm').attr('class');
      let clickedId = divClass.split('-')[1];
      let cls = $(".flt-"+clickedId);
      let dataView = cls.attr("data-view");
      let dataName = cls.attr("data-name");
      let graph_method = "show_"+dataName;

      updateFilterIcon(divClass, clickedId);
      graphsFilter[graph_method](checkedValues);
    });

    // Reset graphs and update checkbox states when the browser window is resized
    window.addEventListener("resize", () => {
      // Update device type filter checkboxes as checked
      document.querySelectorAll("div#filterForm input[type=checkbox]").forEach(element => element.checked = true);

      // Reinitialize charts
      init_charts();

      // Enabling device filter icon
      $("a.flt-category").removeClass("filterIcon").addClass("filterActive");
    }, false);

    $("#sortable").sortable({
        update: function(e, ui) {
          $.ajax({
            url: $(this).data("url"),
            type: "PATCH",
            data: $(this).sortable('serialize'),
          });
        }
      });

    // Hide tooltips when clicking outside
    $('body').on('click', function(event) {
      if (!$(event.target).hasClass('glyphicon-filter') && !$(event.target).hasClass('custom-tooltip')) {
        // Close all tooltips if the click is outside filter icons and tooltips
        $('.custom-tooltip').hide();
      }
    });

    // Prevent tooltips from closing when clicking inside them
    $('.custom-tooltip').click(function(event) {
      event.stopPropagation();
    });
  })
);
